<!-- eslint-disable -->
<template>
  <b-sidebar
    id="sidebar-1"
    ref="mySidebar"
    shadow
    bg-variant="white"
    backdrop
    right
    no-header
    no-close-on-route-change
    v-model="visible"
    @change="returnDefault"
  >
    <div ref="mainSidebar">
      <template>
        <div ref="header">
          <hr class="dropdown-divider" />
          <b-row no-gutters class="align-items-center">
            <b-col class="header-left-col">
              <b-button
                v-if="showConvertButton"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                pill
                class="reset"
                @click="showWarn"
              >
                <span class="align-middle">Convert</span>
              </b-button>

              <b-button
                v-if="showConvertedButton"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                pill
                class="reset"
              >
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Converted</span>
              </b-button>

              <b-button
                v-if="showShippedButton"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                pill
                class="reset-shipped"
              >
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Shipped</span>
              </b-button>

              <b-button
                v-if="showProjectedButton"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                pill
                class="reset-shipped"
              >
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Project</span>
              </b-button>

              <b-button
                v-if="showConvertDealButton"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.modal-select2
                pill
                class="reset-deals"
              >
                <span class="align-middle">Convert</span>
              </b-button>
              <b-modal
                id="modal-select2"
                title="Please Confirm"
                ok-title="submit"
                cancel-variant="outline-secondary"
                centered
                @ok="
                  submitModal();
                  shippmentSelected = false;
                "
                @cancel="
                  cancelModal();
                  shippmentSelected = false;
                "
                @close="
                  closeModal();
                  shippmentSelected = false;
                "
                no-close-on-backdrop
                no-close-on-esc
              >
                <b-form>
                  <b-form-group>
                    <b-form-checkbox
                      id="shippment"
                      v-model="shippmentClicked"
                      @change="
                        projectClicked = false;
                        shippmentSelected = !shippmentSelected;
                      "
                    />
                    <label label-for="shippment">Convert to shippment</label>
                  </b-form-group>
                  <transition name="fade">
                    <div class="order-address d-flex" v-if="shippmentSelected">
                      <b-form-group
                        label="Order"
                        label-for="v-order"
                        class="mr-50"
                      >
                        <b-form-select id="v-order" v-model="selectedOrder">
                          <b-form-select-option :value="null">
                            --
                          </b-form-select-option>
                          <b-form-select-option value="a">
                            Order A
                          </b-form-select-option>
                          <b-form-select-option value="b">
                            Order B
                          </b-form-select-option>
                          <b-form-select-option value="c">
                            Order C
                          </b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                      <b-form-group label="Address" label-for="v-address">
                        <b-form-select id="v-address" v-model="selectedAddress">
                          <b-form-select-option :value="null">
                            --
                          </b-form-select-option>
                          <b-form-select-option value="a">
                            Address A
                          </b-form-select-option>
                          <b-form-select-option value="b">
                            Address B
                          </b-form-select-option>
                          <b-form-select-option value="c">
                            Address C
                          </b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                  </transition>

                  <b-form-group class="project-checkbox">
                    <b-form-checkbox
                      id="project"
                      v-model="projectClicked"
                      @change="shippmentClicked = false"
                    />
                    <label label-for="project">Convert to project</label>
                  </b-form-group>
                </b-form>
                <b-card
                  v-if="warnMsg"
                  bg-variant="danger"
                  text-variant="white"
                  class="mt-1 mb-0"
                >
                  <b-card-text> You must choose or cancel ! </b-card-text>
                </b-card>
              </b-modal>
            </b-col>
            <b-col class="header-right-col">
              <ul class="header">
                <li @click="onlyCalendarStatus()">
                  <b-nav-item
                    :class="{ active: activeTab == 1 }"
                    @click="
                      activate(1);
                      removeActive($event);
                    "
                    :to="
                      this.$router.history.current.path !==
                      `/${this.link}/apps/calendar`
                        ? `/${link}/apps/calendar`
                        : `/${link}/apps/activities`
                    "
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                      class="prevent-click"
                    />
                  </b-nav-item>
                </li>
                <li @click="onlyCalendarStatus()">
                  <b-nav-item
                    :class="{ active: activeTab == 2 }"
                    @click="
                      activate(2);
                      removeActive($event);
                    "
                    :to="
                      this.$router.history.current.path !==
                      `/${this.link}/apps/adresses`
                        ? `/${link}/apps/adresses`
                        : `/${link}/apps/activities`
                    "
                  >
                    <feather-icon
                      icon="MapPinIcon"
                      size="18"
                      class="prevent-click"
                    />
                  </b-nav-item>
                </li>
                <li @click="onlyCalendarStatus()" class="hide-orders">
                  <b-nav-item
                    :class="{ active: activeTab == 3 }"
                    @click="
                      activate(3);
                      removeActive($event);
                    "
                    :to="
                      this.$router.history.current.path !==
                      `/${this.link}/apps/orders`
                        ? `/${link}/apps/orders`
                        : `/${link}/apps/activities`
                    "
                  >
                    <feather-icon
                      icon="ShoppingCartIcon"
                      size="18"
                      class="prevent-click"
                    />
                  </b-nav-item>
                </li>
                <li>
                  <b-nav-item
                    :class="{ active: activeTab == 4 }"
                    @click="
                      activate(4);
                      removeActive($event);
                      onlyCalendar = true;
                    "
                    :to="`/${link}/apps/activities`"
                  >
                    <feather-icon
                      icon="RefreshCwIcon"
                      size="18"
                      class="prevent-click"
                    />
                  </b-nav-item>
                </li>
                <li @click="onlyCalendarStatus()">
                  <b-nav-item
                    :class="{ active: activeTab == 5 }"
                    @click="
                      activate(5);
                      removeActiveFile($event);
                    "
                    :to="
                      this.$router.history.current.path !==
                      `/${this.link}/apps/files`
                        ? `/${link}/apps/files`
                        : `/${link}/apps/activities`
                    "
                  >
                    <label class="label m-0"
                      ><feather-icon
                        icon="PaperclipIcon"
                        size="18"
                        class="prevent-click"
                    /></label>
                  </b-nav-item>
                </li>
                <li>
                  <b-nav-item
                    :class="{ active: activeTab == 6 }"
                    @click="
                      activate(6);
                      removeActive($event);
                      onlyCalendar = true;
                    "
                    :to="`/${link}/apps/activities`"
                  >
                    <feather-icon
                      icon="GitPullRequestIcon"
                      size="18"
                      class="prevent-click"
                    />
                  </b-nav-item>
                </li>
                <li>
                  <b-dropdown
                    id="dropdown-5"
                    ref="dropdown-leads"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="Primary"
                    variant="flat-primary"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-nav-item
                        :class="{ active: activeTab == 7 }"
                        @click="
                          activate(7);
                          removeActive($event);
                          onlyCalendar = true;
                        "
                        :to="`/${link}/apps/activities`"
                      >
                        <feather-icon
                          icon="MoreHorizontalIcon"
                          size="18"
                          class="prevent-click"
                        />
                      </b-nav-item>
                    </template>
                    <b-dropdown-item>Option 1</b-dropdown-item>
                    <b-dropdown-item>Option 2</b-dropdown-item>
                    <b-dropdown-item>Option 3</b-dropdown-item>
                  </b-dropdown>
                </li>
                <li @click="toggleClick">
                  <b-nav-item class="close-btn">
                    <feather-icon icon="XSquareIcon" size="18" />
                  </b-nav-item>
                </li>
              </ul>
            </b-col>
          </b-row>
          <hr class="dropdown-divider" />
        </div>
      </template>
      <VuePerfectScrollbar
        class="scroll-area scrollable-part"
        :settings="settings"
        @ps-scroll-y="scrollHandle"
        ref="scrollable"
        :style="{ height: `${height}px` }"
      >
        <div class="pr-1">
          <template>
            <slot name="row-first-part" />
            <router-view name="calendar" />
            <router-view name="files" />
            <router-view name="adresses" />
            <router-view name="orders" />
            <slot name="row-seconde-part" v-if="onlyCalendar" />
            <hr class="dropdown-divider" v-if="onlyCalendar" />
            <template v-if="onlyCalendar">
              <tab-default />
            </template>
          </template>
        </div>
      </VuePerfectScrollbar>
      <div class="non-scrollable-part" ref="comment">
        <template>
          <hr class="dropdown-divider" />
          <b-row no-gutters class="align-self-stretch">
            <b-col sm="2">
              <b-avatar
                :src="require('@/assets/images/avatars/12-small.png')"
                class="mb-1"
              />
            </b-col>
            <b-col sm="10">
              <b-form-textarea
                ref="textarea"
                placeholder="Ask a question or post an update..."
                no-resize
                @focus="
                  focus = true;
                  fixScroll();
                  calcHeight(0);
                  showCollaborators = true;
                "
                @input="fixScroll"
                @blur="
                  focus = false;
                  stopBlur($event);
                  showCollaborators = false;
                  calcHeight(24);
                "
                @keyup="calcHeight(20)"
                v-model="value"
                class="timeline-textarea"
              />
              <b-row no-gutters v-show="focus" class="p-1 comment">
                <b-col
                  class="d-flex"
                  style="align-items: center"
                  @mousedown="handelBlur"
                  ><b-form-checkbox @input="focusOnCheck" /><span
                    >Mark it as note</span
                  ></b-col
                >
                <b-col class="text-right"
                  ><b-button variant="primary" size="sm">
                    Comment
                  </b-button></b-col
                >
              </b-row>
              <b-dropdown-form
                v-if="value === '@'"
                @mousedown="handelBlur"
                class="profiles-list"
              >
                <b-dropdown-item
                  v-for="(profile, index) in profiles"
                  :key="index"
                  @click="insertProfile($event)"
                >
                  <b-avatar size="sm" :src="profile.avatar" disabled />
                  <span class="ml-50"> {{ profile.name }}</span>
                </b-dropdown-item>
              </b-dropdown-form>
              <div
                class="d-flex align-items-center collaborators mb-1"
                v-if="showCollaborators"
              >
                <span class="text-muted mr-1"> Collaborators </span>
                <b-avatar-group size="32px" class="mt-50" ref="timeline">
                  <b-avatar
                    v-b-tooltip.hover
                    class="pull-up"
                    title="Jenny Looper"
                    alt="Jenny Looper"
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-5.jpg')
                    "
                    button
                    @mousedown="handelBlur()"
                    @click="
                      insertCollaborator($event);
                      focusOnCheck();
                    "
                  />
                  <b-avatar
                    v-b-tooltip.hover
                    class="pull-up"
                    title="Jennifer Lopez"
                    alt="Jennifer Lopez"
                    button
                    @click="
                      insertCollaborator($event);
                      focusOnCheck();
                    "
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-6.jpg')
                    "
                  />
                  <b-avatar
                    v-b-tooltip.hover
                    class="pull-up"
                    title="Arya Stark"
                    alt="Arya Stark"
                    button
                    @click="
                      insertCollaborator($event);
                      focusOnCheck();
                    "
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-7.jpg')
                    "
                  />
                </b-avatar-group>
              </div>
            </b-col>
          </b-row>
        </template>
        <div ref="fixScroll"></div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  VBToggle,
  BButton,
  BRow,
  BCol,
  BNavItem,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
  BFormCheckbox,
  BDropdownForm,
  BAvatar,
  VBTooltip,
  BAvatarGroup,
  BFormGroup,
  BForm,
  BCard,
  BCardText,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { bus } from "@/main";
import TabDefault from "@/views/used-tabs/TabDefault.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BSidebar,
    BButton,
    BRow,
    BCol,
    BNavItem,
    BDropdown,
    BDropdownItem,
    TabDefault,
    BFormTextarea,
    BFormCheckbox,
    BDropdownForm,
    BAvatar,
    BAvatarGroup,
    BFormGroup,
    BForm,
    BCard,
    BCardText,
    BFormSelect,
    BFormSelectOption,
    VuePerfectScrollbar,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    showConvertButton: { type: Boolean, default: false },
    showConvertedButton: { type: Boolean, default: false },
    showConvertDealButton: { type: Boolean, default: false },
    showShippedButton: { type: Boolean, default: false },
    showProjectedButton: { type: Boolean, default: false },
    // eslint-disable-next-line vue/require-default-prop
    clickedRowData: [],
  },
  data() {
    return {
      storedDealsRows: [],
      storedContactsRows: [],
      storedShippmentsRows: [],
      storedProjectsRows: [],
      height: 0,
      settings: {
        maxScrollbarLength: 60,
      },
      row: [],
      // showFile: false,
      onlyCalendar: true,
      // files: null,
      focus: false,
      checked: false,
      value: "",
      visible: false,
      boxWarn: "",
      activeTab: 0,
      /* eslint-disable global-require */
      profiles: [
        {
          avatar: require("@/assets/images/avatars/1-small.png"),
          name: "Jane Foster",
        },
        {
          avatar: require("@/assets/images/avatars/3-small.png"),
          name: "Donna Frank",
        },
        {
          avatar: require("@/assets/images/avatars/5-small.png"),
          name: "Gabrielle Robertson",
        },
        {
          avatar: require("@/assets/images/avatars/7-small.png"),
          name: "Lori Spears",
        },
        {
          avatar: require("@/assets/images/avatars/9-small.png"),
          name: "Sandy Vega",
        },
        {
          avatar: require("@/assets/images/avatars/11-small.png"),
          name: "Cheryl May",
        },
      ],
      shippmentClicked: false,
      projectClicked: false,
      warnMsg: false,
      selectedOrder: null,
      selectedAddress: null,
      shippmentSelected: false,
      showCollaborators: false,
    };
  },
  inject: ["link"],
  mounted() {
    // eslint-disable-next-line operator-linebreak
    this.storedDealsRows =
      JSON.parse(localStorage.getItem("dealsRows")) || this.storedDealsRows;

    // eslint-disable-next-line operator-linebreak
    this.storedContactsRows =
      // eslint-disable-next-line operator-linebreak
      JSON.parse(localStorage.getItem("contactsRows")) ||
      this.storedContactsRows;

    // eslint-disable-next-line operator-linebreak
    this.storedShippmentsRows =
      // eslint-disable-next-line operator-linebreak
      JSON.parse(localStorage.getItem("shippmentsRows")) ||
      this.storedShippmentsRows;

    // eslint-disable-next-line operator-linebreak
    this.storedProjectsRows =
      // eslint-disable-next-line operator-linebreak
      JSON.parse(localStorage.getItem("projectsRows")) ||
      this.storedProjectsRows;

    this.$router.push("/leads-table").catch(() => {});

    this.calcHeightOnMount();
  },
  methods: {
    calcHeightOnMount() {
      bus.$on("on-row-click", () => {
        this.calcHeight(0);
      });
    },

    calcHeight(val) {
      this.$nextTick(() => {
        const header = this.$refs.header.clientHeight;
        const comment = this.$refs.comment.clientHeight;
        this.height = window.innerHeight - (header + comment + 43 - val);
      });
    },

    toggleClick() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-1");
    },

    fixScroll() {
      setTimeout(() => {
        this.$refs.fixScroll.scrollIntoView({ behavior: "smooth" });
      }, 300);
    },

    changeRoute() {
      this.$router.push(`/${this.link}/apps/activities`).catch(() => {});
      bus.$emit("click");
    },

    returnDefault() {
      if (this.$refs.mySidebar.visible) {
        this.$router.push(`/${this.link}`).catch(() => {});
        // this.showFile = false;
        this.onlyCalendar = false;
      } else {
        this.$router.push(`/${this.link}/apps/activities`).catch(() => {});
        this.onlyCalendar = true;
        this.activeTab = 0;
      }
    },

    handelBlur(event) {
      this.stopBlur(event);
    },

    stopBlur(event) {
      event.preventDefault();
      event.stopPropagation();
    },

    focusOnCheck() {
      setTimeout(() => {
        this.$refs.textarea.focus();
      }, 0);
    },

    // hideCollaborators() {
    //   setTimeout(() => {
    //     this.showCollaborators = false;
    //   }, 1000);
    // },

    insertProfile(event) {
      this.value = `@${event.target.textContent};`;
    },

    insertCollaborator(event) {
      this.stopBlur();
      this.value += `@${event.target.alt}; `;
    },

    makeFocus() {
      this.$refs.textarea.focus();
    },

    showWarn() {
      this.boxWarn = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to converte this lead.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        // eslint-disable-next-line arrow-parens
        .then((value) => {
          this.boxWarn = value;
          if (value) {
            this.$parent.$parent.changeBtnStatus();
            this.storedDealsRows.push(this.clickedRowData);
            localStorage.setItem(
              "dealsRows",
              // eslint-disable-next-line comma-dangle
              JSON.stringify(this.storedDealsRows)
            );

            this.storedContactsRows.push(this.clickedRowData);
            localStorage.setItem(
              "contactsRows",
              // eslint-disable-next-line comma-dangle
              JSON.stringify(this.storedContactsRows)
            );
          } else {
            this.showConvertButton = true;
            this.showConvertedButton = false;
          }
        });
    },

    onlyCalendarStatus() {
      setTimeout(() => {
        if (
          this.$router.history.current.path === `/${this.link}/apps/activities`
        ) {
          this.onlyCalendar = true;
        } else {
          this.onlyCalendar = false;
        }
      }, 500);
    },

    activate(el) {
      this.activeTab = el;
    },

    removeActive(event) {
      if (event.target.parentElement.classList.contains("active")) {
        event.target.parentElement.classList.remove("active");
      } else {
        event.target.parentElement.classList.add("active");
      }
    },

    removeActiveFile(event) {
      if (event.target.parentNode.parentNode.classList.contains("active")) {
        event.target.parentNode.parentNode.classList.remove("active");
      } else {
        event.target.parentNode.parentNode.classList.add("active");
      }
    },

    submitModal(bvModalEvt) {
      if (this.shippmentClicked) {
        this.$parent.$parent.changeDealsBtnStatus();
        this.storedShippmentsRows.push(this.clickedRowData);
        localStorage.setItem(
          "shippmentsRows",
          // eslint-disable-next-line comma-dangle
          JSON.stringify(this.storedShippmentsRows)
        );
        this.warnMsg = false;
      } else if (this.projectClicked) {
        this.$parent.$parent.$parent.changeDealsProjectsBtnStatus();
        this.storedProjectsRows.push(this.clickedRowData);
        localStorage.setItem(
          "projectsRows",
          // eslint-disable-next-line comma-dangle
          JSON.stringify(this.storedProjectsRows)
        );
        this.warnMsg = false;
      } else {
        bvModalEvt.preventDefault();
        this.warnMsg = true;
      }
      this.shippmentClicked = false;
      this.projectClicked = false;
    },

    cancelModal() {
      this.shippmentClicked = false;
      this.projectClicked = false;
      this.warnMsg = false;
    },

    closeModal() {
      this.warnMsg = false;
      this.shippmentClicked = false;
      this.projectClicked = false;
    },

    scrollHandle() {},
  },
};
</script>

<style>
#sidebar-1 {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}
.b-sidebar .b-sidebar-body {
  flex-grow: 0;
  height: auto;
  overflow-y: visible;
  padding-bottom: 0;
}
#sidebar-1 .header-navbar-shadow {
  display: none;
}
.scrollable-part {
  overflow-y: hidden;
  width: calc(100% + 13px);
}
.b-sidebar-body,
.b-sidebar-footer {
  padding: 1rem;
}
.b-sidebar-footer {
  padding-top: 0;
}
.reset-note > div {
  display: flex;
  align-items: self-end;
}
.b-sidebar-body .col.app-calendar-sidebar {
  position: absolute;
}
.chat-application .chat-app-window {
  min-height: 500px;
}
.left-header-col {
  display: flex;
  align-items: center;
}
@media (min-width: 992px) {
  .b-sidebar-body .col .fc .fc-sidebarToggle-button {
    display: inline-flex !important;
    font-size: 0 !important;
  }
  .todo-app-list .sidebar-toggle,
  .email-application .sidebar-toggle,
  .chat-application .sidebar-toggle {
    display: block !important;
  }
}
@media (min-width: 767px) {
  #sidebar-1 {
    width: 55%;
  }
}
.form-group div {
  display: flex;
}
.project-checkbox {
  margin-bottom: 0;
}
#dropdown-5 button {
  padding: 0;
}
</style>

<style scoped>
.header {
  display: flex;
  justify-content: right;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.reset {
  padding: 5px 10px;
  display: flex;
}
.label {
  color: #7367f0;
  cursor: pointer;
  margin: 0 10px;
}
.close-btn a {
  padding-right: 0;
}
.note-label {
  font-weight: bold;
  font-size: 1rem;
  transform: translateY(4px);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.timeline-textarea {
  height: 50px;
  transition: all 0.3s ease-in-out;
}
.timeline-textarea:focus {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 75px;
  transition: all 0.3s ease-in-out;
}
.comment {
  width: 100%;
  border: 1px solid #7367f0;
  border-top: none;
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
}
li.profiles-list {
  list-style: none;
}
.app-calendar {
  margin-bottom: 20px;
}
li.nav-item.active {
  background-color: #7367f0;
  border-radius: 0.358rem;
}
li.nav-item.active a,
li.nav-item.active label {
  color: #fff;
  display: flex;
}
.prevent-click {
  pointer-events: none;
}
</style>
