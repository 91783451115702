<template>
  <v-select
    v-model="selected"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    multiple
    label="title"
    :options="option"
    class="d-flex"
  />
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      dir: "ltr",
      selected: [{ title: "Square" }, { title: "Romboid" }],
      option: [
        { title: "Square" },
        { title: "Rectangle" },
        { title: "Rombo" },
        { title: "Romboid" },
        { title: "Trapeze" },
        { title: "Triangle" },
        { title: "Polygon" },
      ],
    };
  },
};
</script>
