<!-- eslint-disable -->
<template>
  <div v-if="visible" id="custom-filtet">
    <b-card class="cus-filter" border-variant="dark">
      <b-form @submit.prevent>
        <b-row>
          <VuePerfectScrollbar
            class="scroll-area"
            style="width: 100%; height: 320px"
            :settings="settings"
            @ps-scroll-y="scrollHandle"
          >
            <b-col cols="12" class="text-left">
              <b-form-group label="Created at" label-cols-md="3">
                <div class="dp-container">
                  <b-form-datepicker
                    class="mr-1 date-picker"
                    placeholder="From"
                    dropright
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />

                  <b-form-datepicker
                    placeholder="To"
                    dropleft
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="text-left">
              <b-form-group label="Follow up" label-cols-md="3">
                <div class="dp-container">
                  <b-form-datepicker
                    class="mr-1 date-picker"
                    placeholder="From"
                    dropright
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />

                  <b-form-datepicker
                    placeholder="To"
                    dropleft
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="text-left">
              <b-form-group label="Last activity" label-cols-md="3">
                <div class="dp-container">
                  <b-form-datepicker
                    class="mr-1 date-picker"
                    placeholder="From"
                    dropright
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />

                  <b-form-datepicker
                    placeholder="To"
                    dropleft
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="text-left">
              <b-form-group label="Source" label-cols-md="3">
                <b-form-select v-model="selectedSource">
                  <b-form-select-option :value="null">
                    --
                  </b-form-select-option>
                  <b-form-select-option value="a"
                    >Source A</b-form-select-option
                  >
                  <b-form-select-option value="b"
                    >Source B</b-form-select-option
                  >
                  <b-form-select-option value="c"
                    >Source C</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="text-left">
              <b-form-group label="Campaign" label-cols-md="3">
                <b-form-select v-model="selectedCampaign">
                  <b-form-select-option :value="null">
                    --
                  </b-form-select-option>
                  <b-form-select-option value="a"
                    >Campaign A</b-form-select-option
                  >
                  <b-form-select-option value="b"
                    >Campaign B</b-form-select-option
                  >
                  <b-form-select-option value="c"
                    >Campaign C</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="text-left">
              <b-form-group label="City" label-cols-md="3">
                <b-form-select v-model="selectedCity">
                  <b-form-select-option :value="null">
                    --
                  </b-form-select-option>
                  <b-form-select-option value="a">
                    City A
                  </b-form-select-option>
                  <b-form-select-option value="b">
                    City B
                  </b-form-select-option>
                  <b-form-select-option value="c">
                    City C
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="text-left">
              <b-form-group label="Assigned" label-cols-md="3">
                <b-form-select v-model="selectedAssigned">
                  <b-form-select-option :value="null">
                    --
                  </b-form-select-option>
                  <b-form-select-option value="a"
                    >Assigned A</b-form-select-option
                  >
                  <b-form-select-option value="b"
                    >Assigned B</b-form-select-option
                  >
                  <b-form-select-option value="c"
                    >Assigned C</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="text-left">
              <b-form-group label="Country" label-cols-md="3">
                <b-form-select v-model="selectedCountry">
                  <b-form-select-option :value="null">
                    --
                  </b-form-select-option>
                  <b-form-select-option value="a"
                    >Country A</b-form-select-option
                  >
                  <b-form-select-option value="b"
                    >Country B</b-form-select-option
                  >
                  <b-form-select-option value="c"
                    >Country C</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="text-left">
              <b-form-group label="Status" label-cols-md="3">
                <b-form-select v-model="selectedStatus">
                  <b-form-select-option :value="null">
                    --
                  </b-form-select-option>
                  <b-form-select-option value="a"
                    >Status A</b-form-select-option
                  >
                  <b-form-select-option value="b"
                    >Status B</b-form-select-option
                  >
                  <b-form-select-option value="c"
                    >Status C</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="text-left">
              <b-form-group label="Name" label-cols-md="3">
                <b-form-input placeholder="Search" />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="text-left">
              <b-form-group label="Stage" label-cols-md="3">
                <b-form-select v-model="selectedStage">
                  <b-form-select-option :value="null">
                    --
                  </b-form-select-option>
                  <b-form-select-option value="a">
                    Stage A
                  </b-form-select-option>
                  <b-form-select-option value="b">
                    Stage B
                  </b-form-select-option>
                  <b-form-select-option value="c">
                    Stage C
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </VuePerfectScrollbar>
          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1 mt-1 set-btn"
            >
              Filter
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-1 set-btn"
              @click="visible = !visible"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-overlay show no-wrap class="overlay" variant="black" />
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BFormDatepicker,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BFormDatepicker,
    BFormSelect,
    BFormSelectOption,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      visible: true,
      selectedSource: null,
      selectedCampaign: null,
      selectedCity: null,
      selectedAssigned: null,
      selectedCountry: null,
      selectedStatus: null,
      selectedStage: null,
      settings: {
        maxScrollbarLength: 60,
      },
      // columns: [],
      // from: new Date(),
      // to: new Date(),
      // disabledDatesFrom: {
      //   to: new Date(),
      //   from: null,
      // },
      // disabledDatesTo: {
      //   to: new Date(),
      // },
    };
  },
  created() {},
  methods: {
    scrollHandle() {},
    // updateDDT(selected) {
    //   this.disabledDatesTo.to = selected;
    //   if (this.to < this.disabledDatesTo.to) {
    //     this.to = this.disabledDatesTo.to;
    //   }
    // },
    // updateDDF(selected) {
    //   this.disabledDatesFrom.from = selected;
    //   if (this.from < this.disabledDatesTo.from) {
    //     this.from = this.disabledDatesTo.from;
    //   }
    // },
  },
};
</script>

<style scoped>
.cus-filter {
  z-index: 13;
  width: 70%;
  margin: -40px auto;
}
.overlay {
  inset: -100px -20px !important;
  height: 100vh;
  width: 100vw;
}
.dp-container {
  display: flex;
}
.set-btn {
  display: inline-block;
}
@media (max-width: 567px) {
  .cus-filter {
    width: 100%;
  }
  .dp-container {
    display: block;
  }
  .date-picker {
    margin-bottom: 10px;
  }
  .set-btn {
    display: block;
    width: 100%;
  }
}
</style>

<style>
#custom-filtet .dp-container {
  width: 100%;
}
</style>
