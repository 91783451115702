<!-- eslint-disable -->
<template>
  <div id="leads">
    <good-table-advance-search
      :rows="rows"
      :columns="columns"
      :columnState="columnState"
      :checkNewDeal="checkNewDeal"
      :showConvertButton="showConvertButton"
      :showConvertedButton="showConvertedButton"
      :clickedRowData="clickedRowData"
      @getClickedRowData="getClickedRowData($event)"
      @checkNewDeal="checkNewDeal"
    >
      <template slot="add-lead-button">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="toggleClick()"
          class="add-lead text-nowrap ml-1"
        >
          Add Lead
        </b-button>
      </template>

      <template slot="kanban-button">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon-table"
          to="/leads-kanban"
        >
          <feather-icon icon="ColumnsIcon" />
        </b-button>
      </template>
    </good-table-advance-search>
    <!--<div class="text-right" style="height: 0"></div>-->
    <add-lead-sidebar :rows="rows" />
  </div>
</template>

<script>
import GoodTableAdvanceSearch from "@/views/used-tables/GoodTableAdvanceSearch.vue";
import AddLeadSidebar from "@/views/used-sidebars/AddLeadSidebar.vue";
import { BButton, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    GoodTableAdvanceSearch,
    BButton,
    AddLeadSidebar,
  },
  provide: {
    link: "leads-table",
    filterName: "apps-todo-leads-filter",
    tagName: "apps-todo-leads-tag",
    appName: "apps-todo",
    emailFilterName: "apps-email-leads-folder",
    labelName: "apps-email-leads-label",
    appEmailName: "apps-email-leads",
  },
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "fullName",
          hidden: false,
          thClass: "custom-th-class",
        },
        {
          label: "Email",
          field: "email",
          hidden: false,
        },
        {
          label: "Phone",
          field: "phone",
          hidden: false,
        },
        {
          label: "Stage",
          field: "stage",
          hidden: false,
        },
        {
          label: "Status",
          field: "status",
          hidden: false,
        },
        {
          label: "Assigned To",
          field: "assignedTo",
          hidden: false,
        },
        {
          label: "Source",
          field: "source",
          hidden: false,
        },
        {
          label: "Page",
          field: "page",
          hidden: false,
        },
        {
          label: "Campaign",
          field: "campaign",
          hidden: true,
        },
        {
          label: "Last Activity",
          field: "lastActivity",
          hidden: true,
        },
        {
          label: "Follow Up",
          field: "followUp",
          hidden: true,
        },
        {
          label: "City",
          field: "city",
          hidden: true,
        },
        {
          label: "Country",
          field: "country",
          hidden: true,
        },
        {
          label: "Lead Age",
          field: "leadAge",
          hidden: true,
        },
      ],
      updatedCols: [],
      rows: [],
      clickedRowData: [],
      dealsRows: [],
      showConvertButton: false,
      showConvertedButton: false,
    };
  },
  created() {
    // eslint-disable-next-line arrow-parens
    this.$http.get("/good-table/advanced-search").then((res) => {
      this.rows = res.data;
    });
  },
  mounted() {
    // eslint-disable-next-line operator-linebreak
    this.columns =
      JSON.parse(localStorage.getItem("updatedCols")) || this.columns;

    this.$router.push("/leads-table").catch(() => {});
  },
  methods: {
    columnState() {
      this.updatedCols = [];
      this.columns.forEach((column, index) => {
        this.updatedCols.push(this.columns[index]);
        localStorage.setItem("updatedCols", JSON.stringify(this.updatedCols));
      });
    },
    toggleClick() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-2");
    },
    getClickedRowData(event) {
      this.clickedRowData = event.row;
    },
    checkNewDeal() {
      // eslint-disable-next-line operator-linebreak
      this.dealsRows =
        JSON.parse(localStorage.getItem("dealsRows")) || this.dealsRows;
      if (
        // eslint-disable-next-line arrow-parens
        this.dealsRows.filter((row) => row.id === this.clickedRowData.id)
          .length > 0
      ) {
        this.showConvertButton = false;
        this.showConvertedButton = true;
      } else {
        this.showConvertButton = true;
        this.showConvertedButton = false;
      }
    },
    changeBtnStatus() {
      this.showConvertButton = false;
      this.showConvertedButton = true;
    },
  },
};
</script>

<style scoped>
.btn-icon-table {
  position: absolute !important;
  top: -57px;
  right: 98px;
}
.add-lead {
  padding: 0.786rem 1.5rem;
}
body {
  position: relative;
}
</style>

<style>
#leads .reset-deals {
  display: none;
}
#leads .hide-orders {
  display: none;
}
#leads .reset-shipped {
  display: none;
}
.custom-th-class {
  color: red;
}
</style>
