<template>
  <b-sidebar
    id="sidebar-2"
    ref="myAddSidebar"
    shadow
    bg-variant="white"
    backdrop
    right
    no-header
    no-close-on-route-change
    width="30rem"
  >
    <template #default="{ hide }">
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
        "
      >
        <h5 class="mb-0">
          Add Lead
        </h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>
      <b-form @submit.prevent>
        <b-row>
          <!-- assignee -->
          <b-col cols="12">
            <b-form-group
              label="Assignee"
              label-for="v-assignee"
            >
              <b-form-input
                id="v-assignee"
                v-model="assignee"
                placeholder="Assignee"
                required
              />
            </b-form-group>
          </b-col>

          <!-- full name -->
          <b-col cols="12">
            <b-form-group
              label="Full Name"
              label-for="v-full-name"
            >
              <b-form-input
                id="v-full-name"
                v-model="fullName"
                placeholder="Full Name"
                required
              />
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col cols="12">
            <b-form-group
              label="Email"
              label-for="v-email"
            >
              <b-form-input
                id="v-email"
                v-model="email"
                type="email"
                placeholder="Email"
                required
              />
            </b-form-group>
          </b-col>

          <!-- phone -->
          <b-col cols="12">
            <b-form-group
              label="Phone"
              label-for="v-phone"
            >
              <b-form-input
                id="v-phone"
                v-model="phone"
                type="number"
                placeholder="Phone"
                required
              />
            </b-form-group>
          </b-col>

          <!--source -->
          <b-col cols="12">
            <b-form-group
              label="Source"
              label-for="v-source"
            >
              <b-form-select
                id="v-source"
                v-model="selectedSource"
              >
                <b-form-select-option :value="null">
                  --
                </b-form-select-option>
                <b-form-select-option value="a">
                  Source A
                </b-form-select-option>
                <b-form-select-option value="b">
                  Source B
                </b-form-select-option>
                <b-form-select-option value="c">
                  Source C
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <!-- status -->
          <b-col cols="12">
            <b-form-group
              label="Status"
              label-for="v-status"
            >
              <b-form-input
                id="v-status"
                v-model="status"
                placeholder="Status"
                required
              />
            </b-form-group>
          </b-col>

          <!-- add and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="addRow"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BSidebar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
  },
  directives: {
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    rows: { type: Array },
  },
  data() {
    return {
      selectedSource: null,
      fullName: "",
      assignee: "",
      email: "",
      phone: "",
      status: "",
    };
  },
  methods: {
    addRow() {
      if (
        // eslint-disable-next-line operator-linebreak
        this.fullName !== "" &&
        // eslint-disable-next-line operator-linebreak
        this.email !== "" &&
        // eslint-disable-next-line operator-linebreak
        this.phone !== "" &&
        // eslint-disable-next-line operator-linebreak
        this.status !== "" &&
        this.assignee !== ""
      ) {
        this.rows.push({
          fullName: this.fullName,
          email: this.email,
          phone: this.phone,
          status: this.status,
          assignedTo: this.assignee,
          source: this.selectedSource,
        });
      }
      this.toggleClick();
      this.sendAllRows();
    },
    toggleClick() {
      if (
        // eslint-disable-next-line operator-linebreak
        this.fullName !== "" &&
        // eslint-disable-next-line operator-linebreak
        this.email !== "" &&
        // eslint-disable-next-line operator-linebreak
        this.phone !== "" &&
        // eslint-disable-next-line operator-linebreak
        this.status !== "" &&
        // eslint-disable-next-line operator-linebreak
        this.assignee !== "" &&
        this.selectedSource !== ""
      ) {
        this.$root.$emit("bv::toggle::collapse", "sidebar-2");
        this.selectedSource = null;
        this.fullName = "";
        this.assignee = "";
        this.email = "";
        this.phone = "";
        this.status = "";
      }
    },
    sendAllRows() {
      this.$emit("getAllRows", this.rows);
    },
  },
};
</script>

<style>
#sidebar-2 .b-sidebar-body {
  padding: 0;
}
#sidebar-2 .content-sidebar-header,
#sidebar-2 form {
  padding: 1rem;
}
</style>
