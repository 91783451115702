<template>
  <div>
    <b-nav>
      <b-nav-item
        :to="`/${link}/apps/activities`"
        :class="{ active: activeTab == 1 }"
        @click="activate(1)"
      >
        Activities
      </b-nav-item>
      <b-nav-item
        :to="`/${link}/apps/calls`"
        :class="{ active: activeTab == 2 }"
        @click="activate(2)"
      >
        Calls
      </b-nav-item>
      <b-nav-item
        :to="`/${link}/apps/todo`"
        :class="{ active: activeTab == 3 }"
        @click="activate(3)"
      >
        To Do
      </b-nav-item>
      <b-nav-item
        :to="`/${link}/apps/email`"
        :class="{ active: activeTab == 4 }"
        @click="activate(4)"
      >
        Email
      </b-nav-item>
      <b-nav-item
        :to="`/${link}/apps/sms`"
        :class="{ active: activeTab == 5 }"
        @click="activate(5)"
      >
        SMS
      </b-nav-item>
      <b-nav-item
        :to="`/${link}/apps/whatsapp`"
        :class="{ active: activeTab == 6 }"
        @click="activate(6)"
      >
        Whatsapp
      </b-nav-item>
    </b-nav>

    <layout-content-renderer-left>
      <router-view />
    </layout-content-renderer-left>
    <router-view name="calls" />
    <router-view name="activities" />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BNav, BNavItem } from "bootstrap-vue";
import { bus } from "@/main";
import LayoutContentRendererLeft from "@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue";

export default {
  components: {
    BNav,
    BNavItem,
    LayoutContentRendererLeft,
  },
  inject: ["link"],
  data() {
    return {
      activeTab: 0,
    };
  },
  mounted() {
    bus.$on("on-row-click", () => {
      this.activeTab = 1;
    });

    bus.$on("click", () => {
      this.activeTab = 1;
    });

    if (this.$router.history.current.path === `/${this.link}/apps/activities`) {
      this.activeTab = 1;
    }
  },
  methods: {
    activate(el) {
      this.activeTab = el;
    },
  },
};
</script>

<style>
#sidebar-1 .content.app-content {
  padding: 15px 0 10px !important;
  margin: 0 auto;
}
#sidebar-1 .content-area-wrapper {
  max-height: 500px;
  height: fit-content;
}
</style>

<style scoped>
a {
  padding: 0.61rem 0.8rem;
}
li.nav-item {
  position: relative;
}
li.nav-item.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  transform: translate3d(0, 0, 0);
  left: 0;
  background: linear-gradient(
    30deg,
    #7367f0,
    rgba(115, 103, 240, 0.5)
  ) !important;
  transition: transform 0.3s;
}
</style>
