<!-- eslint-disable -->
<template>
  <b-card no-body class="main-card">
    <div class="text-right good-table" style="height: 0">
      <!-- Start Columns Dropdown -->
      <div slot="table-actions" style="z-index: ">
        <div class="button-group">
          <b-dropdown
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            right
            variant="primary"
            aria-hidden="true"
            no-caret
            class="set-btn-height"
          >
            <template #button-content class="p-1">
              <feather-icon icon="SettingsIcon" />
            </template>

            <b-dropdown-form class="dropdown-menu">
              <VuePerfectScrollbar
                class="scroll-area"
                :settings="settings"
                @ps-scroll-y="scrollHandle"
              >
                <li
                  v-for="(column, index) in columns"
                  :key="index"
                  class="mb-1 mt-1 d-flex"
                >
                  <a
                    @click.prevent="
                      toggleColumn(index, $event), callColumnsState()
                    "
                  >
                    <b-form-checkbox :checked="!column.hidden" />
                  </a>
                  <label for="index">{{ column.label }}</label>
                </li>
              </VuePerfectScrollbar>
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </div>
      <!-- End Columns Dropdown -->

      <!-- Start Filter Dropdown -->
      <b-dropdown
        id="dropdown-1"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        right
        variant="primary"
        aria-hidden="true"
        no-caret
        class="btn-icon set-btn-height mr-4"
        @show="visible = false"
      >
        <template #button-content>
          <feather-icon icon="FilterIcon" />
        </template>

        <b-dropdown-form class="dropdown-menu set-width">
          <div class="custom-search text-left">
            <b-row>
              <VuePerfectScrollbar
                class="scroll-area"
                style="width: 200px; height: 300px"
                :settings="settings"
                @ps-scroll-y="scrollHandle"
              >
                <b-dropdown-item
                  ><feather-icon icon="UserIcon" class="mr-50 mb-25" />Just my
                  leads</b-dropdown-item
                >
                <b-dropdown-item
                  ><feather-icon icon="CalendarIcon" class="mr-50 mb-25" />Due
                  this week</b-dropdown-item
                >
                <b-dropdown-item
                  ><feather-icon icon="CalendarIcon" class="mr-50 mb-25" />Due
                  this month</b-dropdown-item
                >
                <b-dropdown-divider />
                <b-col v-for="(column, index) in columns" :key="index">
                  <b-form-group
                    :label="column.label"
                    label-for="search"
                    v-if="columns[index].hidden === false"
                  >
                    <b-form-input
                      placeholder="Search"
                      type="text"
                      class="d-inline-block"
                      @input="advanceSearch"
                      id="search"
                    />
                  </b-form-group>
                </b-col>
              </VuePerfectScrollbar>
            </b-row>
          </div>
          <b-dropdown-divider />
          <b-dropdown-item
            @click="
              visible = !visible;
              show = true;
            "
            ><feather-icon icon="PlusIcon" class="mr-50 mb-25" />Custom
            filter</b-dropdown-item
          >
        </b-dropdown-form>
      </b-dropdown>
      <!-- End Filter Dropdown -->

      <!-- Start Custom Filter -->
      <custom-filter-basic v-if="visible" />
      <!-- End Custom Filter -->
    </div>
    <slot name="kanban-button" />
    <!-- table -->

    <b-row no-gutters class="p-2">
      <b-col cols="12" md="6"></b-col>
      <b-col cols="12" md="6" class="d-flex">
        <b-form-input
          placeholder="Search"
          type="text"
          @input="advanceSearch"
          id="search"
          class="search-width d-inline-block"
        />
        <slot name="add-lead-button" />
      </b-col>
    </b-row>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :dealsRows="dealsRows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
      @on-row-click="
        toggleClick();
        getClickedRowData($event);
        activateFirstTab();
        calcHeights();
        callCheckNewDeal();
        callCheckNewShipment();
      "
    >
      <template slot="table-row" slot-scope="props">
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
          style="font-size: 1rem"
        >
          <b-avatar :src="props.row.avatar" class="mr-1" size="md" />
          <span class="font-weight-bold">{{ props.row.fullName }}</span>
        </span>
        <span v-else style="font-size: 1rem">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="table-column" slot-scope="props">
        <div class="header">
          <span class="text-uppercase mr-1">{{ props.column.label }}</span>
          <span class="col-header">
            <feather-icon
              icon="CodeIcon"
              size="11"
              style="transform: rotateZ(90deg)"
            />
          </span>
        </div>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap p-1">
          <div class="p-2 d-flex align-items-center set-select">
            <span>Show</span>
            <div class="ml-50 mr-50">
              <v-select
                v-model="pageLength"
                :clearable="false"
                :options="pageLengthOptions"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
            </div>
            <span>entries</span>
          </div>
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <span class="text-nowrap">&nbsp;{{ pageLength }}&nbsp;</span>
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div></div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- Start sidebar -->
    <sidebar-basic
      :showConvertButton="showConvertButton"
      :showConvertedButton="showConvertedButton"
      :showConvertDealButton="showConvertDealButton"
      :showShippedButton="showShippedButton"
      :showProjectedButton="showProjectedButton"
      :clickedRowData="clickedRowData"
    >
      <template slot="row-first-part">
        <div>
          <h1>{{ row.fullName }}</h1>
          <b-badge pill variant="primary" class="mr-1 mb-1 badge-glow">{{
            row.created
          }}</b-badge>
          <b-badge pill variant="primary" class="mr-1 mb-1 badge-glow">{{
            row.stage
          }}</b-badge>
          <b-badge pill variant="primary" class="badge-glow">{{
            row.status
          }}</b-badge>
          <hr class="dropdown-divider" />
        </div>
      </template>
      <template slot="row-seconde-part">
        <b-row class="pt-1 pb-1 align-items-center" no-gutters>
          <b-col sm="3"><label>Assignee</label></b-col>
          <b-col sm="9">
            <b-media class="align-items-center">
              <template #aside>
                <b-avatar
                  :src="require('@/assets/images/avatars/12-small.png')"
                />
              </template>
              <h6>John Doe</h6>
            </b-media>
          </b-col>
        </b-row>
        <b-row class="pt-1 pb-1 align-items-center" no-gutters>
          <b-col sm="3">
            <label>Next action</label>
          </b-col>
          <b-col sm="9"
            ><b-form-datepicker
              style="width: 65%"
              placeholder="MM-DD-YYYY"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
          /></b-col>
        </b-row>
        <b-row class="pt-1 pb-1 align-items-center" no-gutters>
          <b-col sm="3"><label>Products</label></b-col>
          <b-col sm="9">
            <vue-select-multiple />
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col sm="5" class="mb-1">
            <label class="mr-50">
              <feather-icon icon="PhoneIcon" size="14" />
            </label>
            <span>{{ row.phone }}</span>
          </b-col>
          <b-col sm="7" class="mb-1">
            <label class="mr-50">
              <feather-icon icon="MailIcon" size="14" />
            </label>
            <span>{{ row.email }}</span>
          </b-col>
          <b-col sm="12">
            <label class="mr-50">
              <feather-icon icon="MapPinIcon" size="14" />
            </label>
            <span>{{ row.country }} - {{ row.city }}</span>
          </b-col>
        </b-row>
      </template>
    </sidebar-basic>
    <!-- End sidebar -->
  </b-card>
</template>

<script>
import {
  BAvatar,
  BMedia,
  BPagination,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  VBToggle,
  BDropdown,
  BDropdownForm,
  BDropdownItem,
  BDropdownDivider,
  BFormCheckbox,
  BBadge,
  BFormDatepicker,
  BCard,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import SidebarBasic from "@/views/used-sidebars/SidebarBasic.vue";
import CustomFilterBasic from "@/views/custom-filter/CustomFilterBasic.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { bus } from "@/main";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueSelectMultiple from "@/views/used-forms/VueSelectMultiple.vue";

export default {
  components: {
    BCard,
    VueGoodTable,
    SidebarBasic,
    CustomFilterBasic,
    VuePerfectScrollbar,
    BAvatar,
    BMedia,
    BPagination,
    BFormGroup,
    BFormInput,
    vSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownForm,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
    BBadge,
    BFormDatepicker,
    VueSelectMultiple,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    columns: { type: Array },
    // eslint-disable-next-line vue/require-default-prop
    rows: { type: Array },
    // eslint-disable-next-line vue/require-default-prop
    dealsRows: { type: Array },
    // eslint-disable-next-line vue/require-default-prop
    columnState: { type: Function },
    // eslint-disable-next-line vue/require-default-prop
    checkNewDeal: { type: Function },
    // eslint-disable-next-line vue/require-default-prop
    checkNewShipment: { type: Function },
    showConvertButton: { type: Boolean, default: false },
    showConvertedButton: { type: Boolean, default: false },
    showShippedButton: { type: Boolean, default: false },
    showProjectedButton: { type: Boolean, default: false },
    showConvertDealButton: { type: Boolean, default: false },
  },
  data() {
    return {
      clickedRowData: [],
      visible: false,
      pageLength: 5,
      pageLengthOptions: [3, 5, 10],
      dir: false,
      searchTerm: "",
      settings: {
        maxScrollbarLength: 60,
      },
      row: [],
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    // pageLength(e) {
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   this.pageLength = e;
    //   return this.pageLength;
    // },
  },
  // created() {
  //   // eslint-disable-next-line arrow-parens
  //   this.$http.get("/good-table/advanced-search").then((res) => {
  //     this.rows = res.data;
  //   });
  // },
  methods: {
    advanceSearch(val) {
      this.searchTerm = val;
    },
    toggleClick() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-1");
    },
    // eslint-disable-next-line no-unused-vars
    toggleColumn(index) {
      this.$set(this.columns[index], "hidden", !this.columns[index].hidden);
    },
    callColumnsState() {
      this.columnState();
    },
    callCheckNewDeal() {
      this.$emit("checkNewDeal");
    },
    callCheckNewShipment() {
      this.$emit("checkNewShipment");
    },
    scrollHandle() {},
    scrollxHandle() {},
    getClickedRowData(event) {
      this.row = event.row;
      this.clickedRowData = event.row;
      this.$emit("getClickedRowData", event);
    },
    activateFirstTab() {
      bus.$emit("on-row-click");
    },
    calcHeights() {
      bus.$emit("on-row-click");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.btn {
  padding: 0.715rem 0.736rem;
}
th.vgt-left-align.sortable,
td.vgt-left-align {
  white-space: nowrap;
}
table.vgt-table td {
  vertical-align: baseline;
}
table.vgt-table {
  border: 0;
}
.vgt-table.bordered td,
.vgt-table.bordered th {
  border-left: 0;
  border-right: 0;
}
td.vgt-left-align,
th.vgt-left-align {
  padding: 0.72rem 2rem !important;
}
.vgt-table th.sortable::after,
.vgt-table th.sortable::before {
  display: none;
}
body.dark-layout .vgt-wrap .vgt-table th {
  background-color: #343d55;
}
.vgt-wrap .vgt-table th {
  background-color: #f3f2f7;
}
body.dark-layout .vgt-wrap .vgt-table th span {
  color: #d0d2d6;
}
.vs__selected-options {
  min-width: 40px;
}
</style>

<style lang="scss" scoped>
/*.card-body {
  padding: 10px 10px 0;
}
.card-body .card {
  max-height: 0.1rem;
  padding: 0;
  margin: 0;
}*/
.set-btn-height {
  position: absolute;
  top: -57px;
  right: 0;
  z-index: 1;
}
.vgt-global-search {
  padding: 0;
  border: none;
}
li.drop-item {
  display: flex;
}
.dropdown-menu {
  top: 0;
  right: 0;
}
.button-group {
  height: 0;
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 125px;
  height: 250px;
}
.set-width {
  width: 200px;
  transform: translateX(-60px);
}
/*.set-margin {
  margin-right: 115px;
}
.select-result {
  display: flex;
  align-items: center;
  white-space: nowrap;
}*/
.items {
  width: 100%;
}
.b-dropdown-form {
  padding: 0;
}
label {
  font-size: 1rem;
}
/*.show-entries {
  width: auto;
  min-width: 85px;
  margin-left: 5px;
  margin-right: 5px;
}*/
.header {
  display: flex;
  justify-content: space-between;
  font-size: 0.857rem;
}
/*.search-name {
  position: absolute;
  right: 145px;
  width: auto;
  top: 22px;
  min-width: 300px;
}
.search-name-parent {
  display: flex;
  justify-content: right;
}*/
.set-container {
  display: flex;
  justify-content: right;
  align-items: center;
}
.col-header {
  display: flex;
  align-items: center;
}
.set-select {
  position: absolute;
  top: -80px;
  left: 0;
}
</style>
